export default {
  de: {
    singenwiedievoegel: 'Singen wie die Vögel',
    naturzentrum: 'BirdLife-Naturzentrum Neeracherried',
    ausstellung: 'Sonderausstellung Vogelgesang',
    start: 'Start',
    cancel: 'Abbrechen',
    save: 'Speichern',
    next: 'Weiter'
  },
  fr: {
    singenwiedievoegel: 'Chanter comme un oiseau',
    naturzentrum: 'Centre-nature BirdLife de Neeracherried',
    ausstellung: 'Exposition temporaire',
    start: 'Départ',
    cancel: 'Annuler',
    save: 'Enregistrer',
    next: 'Continuer'
  }
};
